// noinspection SpellCheckingInspection

import styled from 'styled-components'

import bankTransferLogo from '../../assets/methods/bank-transfer.svg'
import bankyLogo from '../../assets/methods/banky.png'
import boletoLogo from '../../assets/methods/boleto.png'
import btvoucherLogo from '../../assets/methods/btvoucher.png'
import cashtoCodeLogo from '../../assets/methods/cashtocode.png'
import creditCardLogo from '../../assets/methods/credit-card.png'
import cryptoLogo from '../../assets/methods/crypto.svg'
import efectyLogo from '../../assets/methods/efecty.png'
import enairaLogo from '../../assets/methods/enaira.png'
import indianNetBankingLogo from '../../assets/methods/indian-bank.png'
import instantBankTransferLogo from '../../assets/methods/instant-bank-transfer.svg'
import interacLogo from '../../assets/methods/interac.png'
import jetonCashLogo from '../../assets/methods/jeton_cash.png'
import jetonWalletLogo from '../../assets/methods/jeton.png'
import khipuLogo from '../../assets/methods/khipu.png'
import kingdomCashLogo from '../../assets/methods/kingdom-cash.png'
import kingdomWalletLogo from '../../assets/methods/kingdom-wallet.png'
import mpesaLogo from '../../assets/methods/m_pesa.png'
import mobileMoneyLogo from '../../assets/methods/mobile-money.png'
import monetPayLogo from '../../assets/methods/monet-pay.png'
import p24Logo from '../../assets/methods/p24.png'
import pagoEfectivoLogo from '../../assets/methods/pago_efectivo_cash.png'
import paycashCashLogo from '../../assets/methods/paycash_cash.png'
import paynetCashLogo from '../../assets/methods/paynet_cash.png'
import pecFlashLogo from '../../assets/methods/pec_flash.png'
import pipitLogo from '../../assets/methods/pipit.png'
import pixLogo from '../../assets/methods/pix.png'
import pseLogo from '../../assets/methods/pse-logo.png'
import qrPaymentLogo from '../../assets/methods/qr_payment.png'
import safetyPayLogo from '../../assets/methods/safetypay.png'
import speiLogo from '../../assets/methods/spei.png'
import upiLogo from '../../assets/methods/upi.png'

export const PaymentMethodsWrapper = styled.div`
  .row {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.9rem;

    margin-top: 2rem;
    :first-of-type {
      margin-top: 0;
    }

    .label {
      color: #85a0ad;
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }
  }
`

export const PaymentMethodsEmpty = styled.div`
  color: #808080;
  font-style: normal;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
`

export const PaymentMethodsTitle = styled.div`
  color: #000000;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;

  margin-bottom: 2rem;
`

export const PaymentMethodsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  &.use-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }
`

export const PaymentMethodButton = styled.div`
  cursor: pointer;
  position: relative;

  width: 100%;
  max-width: 30%;
  padding: 0;

  .use-grid & {
    max-width: unset;
  }

  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  color: #000000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem;
  text-align: center;

  &.selected {
    color: #000000;
  }

  :hover {
    color: #000000;
  }
`

export const PaymentMethodChecked = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(7px, -7px);
`

export const PaymentMethodImage = styled.span`
  position: relative;
  width: 10rem;
  height: 10rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;

  &.selected {
    background-color: rgba(68, 204, 0, 0.1);
    border: 1px solid rgba(68, 204, 0, 0.5);
  }

  &.image-bankwire {
    //prettier-ignore
    background-image: url("${bankTransferLogo}");
  }
  &.image-banky {
    //prettier-ignore
    background-image: url("${bankyLogo}");
  }
  &.image-boleto {
    //prettier-ignore
    background-image: url("${boletoLogo}");
  }
  &.image-btvoucher {
    //prettier-ignore
    background-image: url("${btvoucherLogo}");
  }
  &.image-cash_to_code {
    //prettier-ignore
    background-image: url("${cashtoCodeLogo}");
  }
  &.image-credit_card {
    //prettier-ignore
    background-image: url("${creditCardLogo}");
  }
  &.image-crypto,
  &.image-instant_crypto {
    //prettier-ignore
    background-image: url("${cryptoLogo}");
  }
  &.image-efecty_cash {
    //prettier-ignore
    background-image: url("${efectyLogo}");
  }
  &.image-e_naira {
    //prettier-ignore
    background-image: url("${enairaLogo}");
  }
  &.image-indian_net_banking {
    //prettier-ignore
    background-image: url("${indianNetBankingLogo}");
  }
  &.image-instant_bank_transfer,
  &.image-direct_instant_bank_transfer,
  &.image-community_instant_bank_transfer {
    //prettier-ignore
    background-image: url("${instantBankTransferLogo}");
  }
  &.image-interac {
    //prettier-ignore
    background-image: url("${interacLogo}");
  }
  &.image-jeton,
  &.image-jeton_cash {
    //prettier-ignore
    background-image: url("${jetonCashLogo}");
  }
  &.image-jeton_wallet {
    //prettier-ignore
    background-image: url("${jetonWalletLogo}");
  }
  &.image-khipu {
    //prettier-ignore
    background-image: url("${khipuLogo}");
  }
  &.image-kingdom_cash {
    //prettier-ignore
    background-image: url("${kingdomCashLogo}");
  }
  &.image-kingdom_wallet {
    //prettier-ignore
    background-image: url("${kingdomWalletLogo}");
  }
  &.image-m_pesa {
    //prettier-ignore
    background-image: url("${mpesaLogo}");
  }
  &.image-mobile_money {
    //prettier-ignore
    background-image: url("${mobileMoneyLogo}");
  }
  &.image-p24 {
    //prettier-ignore
    background-image: url("${p24Logo}");
  }
  &.image-monet_pay_bank,
  &.image-monet_pay_cash {
    //prettier-ignore
    background-image: url("${monetPayLogo}");
    background-size: 70%;
  }
  &.image-pago_efectivo_bank,
  &.image-pago_efectivo_cash {
    //prettier-ignore
    background-image: url("${pagoEfectivoLogo}");
  }
  &.image-paycash_cash {
    //prettier-ignore
    background-image: url("${paycashCashLogo}");
  }
  &.image-paynet_cash {
    //prettier-ignore
    background-image: url("${paynetCashLogo}");
  }
  &.image-pec_flash {
    //prettier-ignore
    background-image: url("${pecFlashLogo}");
  }
  &.image-pipit {
    //prettier-ignore
    background-image: url("${pipitLogo}");
  }
  &.image-pix {
    //prettier-ignore
    background-image: url("${pixLogo}");
  }
  &.image-pse {
    //prettier-ignore
    background-image: url("${pseLogo}");
  }
  &.image-qr_payment,
  &.image-bank_qr_payment {
    //prettier-ignore
    background-image: url("${qrPaymentLogo}");
  }
  &.image-safetypay_bank,
  &.image-safetypay_cash {
    //prettier-ignore
    background-image: url("${safetyPayLogo}");
  }
  &.image-spei {
    //prettier-ignore
    background-image: url("${speiLogo}");
  }
  &.image-upi {
    //prettier-ignore
    background-image: url("${upiLogo}");
  }
`

export const MethodPaneWrapper = styled.div`
  background: rgba(49, 131, 255, 0.05);
  border: 1px solid rgba(0, 97, 232, 0.3);
  border-radius: 8px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.3rem 2rem;

  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;

  .icon {
    width: 3.5rem;
    height: 3.5rem;
    background-size: contain;
    border: none;
  }
`
